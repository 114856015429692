import React from 'react'

const InfoSkeleton = () => {
    return (
        <div className='bg-yellow-50 py-3'> <div className="animate-pulse flex space-x-4   items-center justify-between max-w-[1500px] mx-auto xxl:px-4 ">
            <div className='flex justify-between items-center text-black w-full md:gap-1'>
                <div className='flex md:gap-2 gap-10 '>
                    <div >
                        <div className="h-4 bg-slate-700 rounded w-28"></div>
                        <div className="h-2 mt-1 bg-slate-700 rounded w-20"></div>
                    </div>
                    <div >
                        <div className="h-4 bg-slate-700 rounded w-28"></div>
                        <div className="h-2 mt-1 bg-slate-700 rounded w-20"></div>
                    </div>

                </div>
                <div className='flex  gap-3 flex-wrap'>
                    <div className="rounded-full bg-slate-700 h-10 w-28"></div>
                    <div className="rounded-full bg-slate-700 h-10 w-10 md:hidden"></div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default InfoSkeleton
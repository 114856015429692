import React from 'react'

const AboutSkeleton = () => {
    return (
        <div className="animate-pulse flex flex-col items-center justify-between max-w-[1500px] mx-auto xxl:px-4 ">
            <div className='w-72 h-12 bg-slate-700 mx-auto mt-20 rounded'></div>
            <div className='w-full h-36 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='w-1/2 h-12 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='w-3/4 h-12 bg-slate-700 mx-auto mt-5 rounded-full'></div>

            <div className='flex justify-between items-center gap-5 w-full mt-5'>
                <div className='h-44 w-1/3 bg-slate-700 rounded'></div>
                <div className='h-44 w-1/3 bg-slate-700 rounded'></div>
                <div className='h-44 w-1/3 bg-slate-700 rounded'></div>
            </div>
        </div>
    )
}

export default AboutSkeleton
import React, { useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { MasonryLayout } from "@appnest/masonry-layout";
import { CiSearch } from "react-icons/ci";

import NavBar from "../components/Navbar";
import Info from "../components/MogCoinNew/Info";
import { gifs, images, memes, videos } from "../helper/Constants";



const ContentDemo = () => {
    const [tab, setTab] = useState("images");
    const [loading, setLoading] = useState(false);

    const handleTab = (i) => {
        setLoading(true);
        setTimeout(() => {
            setTab(i);
            setLoading(false);
        }, 2000);
    };

    const handleDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.open(url, '_blank');
    };

    return (
        <div
            className="bg-fixed font-MonumentExtended object-cover bg-cover overflow-hidden w-screen min-h-screen relative"
            style={{
                backgroundImage: "url('assets/images/coin_bg.webp')",
                backgroundColor: "#000",
                maxWidth: "100vw",
            }}
        >
            <div className="max-w-full overflow-x-hidden ">
                <Info />
                <NavBar />

                {/* Main Content Area */}
                <div className="pt-20 px-4">
                    <div className="max-w-[1500px] mx-auto ">
                        <h1 className="text-5xl text-center lg:text-3xl stroke-text text-primary uppercase font-MonumentExtendedUltrabold mb-16">
                            MOG Content
                        </h1>

                        <div className="relative">
                            <div className="flex sticky z-10 top-0 lg:flex-col-reverse lg:items-start flex-wrap justify-between items-center gap-5 mt-10 ">
                                <div className="flex flex-wrap gap-5 ">
                                    <div
                                        onClick={() => handleTab("images")}
                                        className={`${tab === "images" ? "bg-primary " : "border bg-transparent"
                                            } cursor-pointer px-5 rounded-full py-2`}
                                    >
                                        images{" "}
                                    </div>
                                    <div
                                        onClick={() => handleTab("gifs")}
                                        className={`${tab === "gifs" ? "bg-primary " : "border bg-transparent"
                                            } cursor-pointer px-5 rounded-full py-2`}
                                    >
                                        gifs{" "}
                                    </div>
                                    <div
                                        onClick={() => handleTab("memes")}
                                        className={`${tab === "memes" ? "bg-primary " : "border bg-transparent"
                                            } cursor-pointer px-5 rounded-full py-2`}
                                    >
                                        memes{" "}
                                    </div>
                                    <div
                                        onClick={() => handleTab("videos")}
                                        className={`${tab === "videos" ? "bg-primary " : "border bg-transparent"
                                            } cursor-pointer px-5 rounded-full py-2`}
                                    >
                                        videos{" "}
                                    </div>
                                </div>
                                <div>
                                    <div className="relative flex  items-center">
                                        <input
                                            type="text"
                                            placeholder="Search by name..."
                                            className="h-14 ps-16 rounded-lg  bg-transparent border text-white w-full"
                                        />
                                        <CiSearch className="absolute ms-5" fontSize={24} />
                                    </div>
                                </div>

                            </div>
                            {/* Content sections will go here */}



                            {/* Show loading indicator if content is loading */}
                            {loading ? (
                                <div className="flex justify-center items-center mt-40 animate-pulse-opacity ">Loading...</div>
                            ) : (
                                <div className="my-10">
                                    {tab == "images" && (
                                        <div className={`grid grid-cols-4 xl:grid-cols-3 md:!grid-cols-2 xs:!grid-cols-1 gap-2`} >
                                            {images?.map(({ img, name, rating }, index) => (
                                                <div className="relative group overflow-hidden">
                                                    <img
                                                        className="w-full item h-80 object-cover object-center  hover:scale-125 transition-all ease-out duration-500"
                                                        src={img}
                                                        alt="artist images "
                                                        slot={index}
                                                    />
                                                    <button
                                                        onClick={() => handleDownload(img, name)}
                                                        className="absolute group-hover:opacity-100 opacity-0 duration-300 bottom-5 right-5 bg-primary rounded-lg flex justify-center items-center cursor-pointer text-sm px-3 py-1 gap-2"
                                                    >
                                                        Download <FaArrowDown size={14} />
                                                    </button>

                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {tab == "gifs" && (
                                        <div className="grid grid-cols-4 xl:grid-cols-3 md:!grid-cols-2 xs:!grid-cols-1 gap-2" >
                                            {gifs?.map(({ img, name, rating }, index) => (
                                                <div className="relative group overflow-hidden">
                                                    <img
                                                        className="w-full item h-80 object-cover object-center  "
                                                        src={img}
                                                        alt="artist images"
                                                        slot={index}
                                                    />
                                                    <button
                                                        onClick={() => handleDownload(img, name)}
                                                        className="absolute group-hover:opacity-100 opacity-0 duration-300 bottom-5 right-5 bg-primary rounded-lg flex justify-center items-center cursor-pointer text-sm px-3 py-1 gap-2"
                                                    >
                                                        Download <FaArrowDown size={14} />
                                                    </button>

                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {tab == "memes" && (
                                        <div className="grid grid-cols-4 xl:grid-cols-3 md:!grid-cols-2 xs:!grid-cols-1 gap-2" >
                                            {memes?.map(({ img, name, rating }, index) => (
                                                <div className="relative group overflow-hidden">
                                                    <img
                                                        className="w-full item h-80 object-cover object-center  hover:scale-125 transition-all ease-out duration-500"
                                                        src={img}
                                                        alt="artist images"
                                                        slot={index}
                                                    />
                                                    <button
                                                        onClick={() => handleDownload(img, name)}
                                                        className="absolute group-hover:opacity-100 opacity-0 duration-300 bottom-5 right-5 bg-primary rounded-lg flex justify-center items-center cursor-pointer text-sm px-3 py-1 gap-2"
                                                    >
                                                        Download <FaArrowDown size={14} />
                                                    </button>

                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {tab == "videos" && (
                                        <div className="grid grid-cols-4 xl:grid-cols-3 md:!grid-cols-2 xs:!grid-cols-1 gap-2">
                                            {videos?.map(({ img, name, rating }, index) => (
                                                <div className="relative group overflow-hidden">
                                                    <video
                                                        loop
                                                        playsInline
                                                        controls={true}
                                                        slot={index}
                                                        className="w-full item object-cover  h-80"
                                                        style={{ objectPosition: "center" }}
                                                    >
                                                        <source
                                                            src={img}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                    <button
                                                        onClick={() => handleDownload(img, name)}
                                                        className="absolute group-hover:opacity-100 opacity-0 duration-300 top-5 right-5 bg-primary rounded-lg flex justify-center items-center cursor-pointer text-sm px-3 py-1 gap-2"
                                                    >
                                                        Download <FaArrowDown size={14} />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentDemo;

import React from 'react'

const TradeSkeleton = () => {
    return (
        <div className="animate-pulse flex flex-col items-center justify-between max-w-[1500px] mx-auto xxl:px-4 ">
            <div className='w-10/12 h-12 bg-slate-700 mx-auto rounded'></div>

            <div className='w-full mt-10 h-12 bg-slate-700 mx-auto rounded'></div>
            <div className='w-full mt-5 h-12 bg-slate-700 mx-auto rounded'></div>
            <div className='w-full mt-5 h-12 bg-slate-700 mx-auto rounded'></div>
            <div className='w-full mt-5 h-12 bg-slate-700 mx-auto rounded'></div>
            <div className='w-full mt-5 h-12 bg-slate-700 mx-auto rounded'></div>
            <div className='w-full mt-5 h-12 bg-slate-700 mx-auto rounded'></div>

            <div className='w-72 h-12 bg-slate-700 mx-auto mt-20 rounded'></div>
            <div className='w-1/3 h-16 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='w-3/4 h-12 bg-slate-700 mx-auto mt-5 rounded-full'></div>
            <div className='w-1/2 h-12 bg-slate-700 mx-auto mt-5 rounded-full'></div>
        </div>
    )
}

export default TradeSkeleton
import React, { useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { MasonryLayout } from "@appnest/masonry-layout";

import NavBar from "../components/Navbar";
import Info from "../components/MogCoinNew/Info";
import { gifs, images, memes, videos } from "../helper/Constants";

const Content = () => {
    const [tab, setTab] = useState("images");
    const [loading, setLoading] = useState(false);

    const handleTab = (i) => {
        setLoading(true);
        setTimeout(() => {
            setTab(i);
            setLoading(false);
        }, 2000);
        // const masonryElement = document.querySelector("masonry-layout");
        // if (masonryElement) {
        //     masonryElement.layout();
        // }
    };
    const handleDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // window.open(url, '_blank');
    };
    return (
        <div
            className="bg-fixed font-MonumentExtended object-cover bg-cover overflow-hidden w-screen min-h-screen relative"
            style={{
                backgroundImage: "url('assets/images/coin_bg.webp')",
                backgroundColor: "#000",
                maxWidth: "100vw",
            }}
        >
            <div className="max-w-full overflow-x-hidden">
                <Info />
                <NavBar />

                {/* Main Content Area */}
                <div className="pt-20">
                    <div className="max-w-[1500px] mx-auto xl:px-4">
                        <h1 className="text-5xl text-center lg:text-3xl stroke-text text-primary uppercase font-MonumentExtendedUltrabold mb-16">
                            MOG Content
                        </h1>
                        <div className="relative flex  items-center">
                            <input
                                type="text"
                                placeholder="Search by name..."
                                className="h-14 ps-16 rounded-lg  bg-transparent border text-white w-full"
                            />
                            <CiSearch className="absolute ms-5" fontSize={24} />
                        </div>

                        <div className="flex flex-wrap gap-5 mt-10 ">
                            <div
                                onClick={() => handleTab("images")}
                                className={`${tab === "images" ? "bg-primary " : "border bg-transparent"
                                    } cursor-pointer px-5 rounded-full py-2`}
                            >
                                images{" "}
                            </div>
                            <div
                                onClick={() => handleTab("gifs")}
                                className={`${tab === "gifs" ? "bg-primary " : "border bg-transparent"
                                    } cursor-pointer px-5 rounded-full py-2`}
                            >
                                gifs{" "}
                            </div>
                            <div
                                onClick={() => handleTab("memes")}
                                className={`${tab === "memes" ? "bg-primary " : "border bg-transparent"
                                    } cursor-pointer px-5 rounded-full py-2`}
                            >
                                memes{" "}
                            </div>
                            <div
                                onClick={() => handleTab("videos")}
                                className={`${tab === "videos" ? "bg-primary " : "border bg-transparent"
                                    } cursor-pointer px-5 rounded-full py-2`}
                            >
                                videos{" "}
                            </div>
                        </div>
                        {/* Content sections will go here */}

                        {/* Show loading indicator if content is loading */}
                        {loading ? (
                            <div className="flex justify-center items-center mt-40 animate-pulse-opacity ">Loading...</div>
                        ) : (
                            <div className="mt-10">
                                {tab == "images" && (
                                    <masonry-layout gap="10" debounce="500" >
                                        {images?.map(({ img, name, rating }, index) => (
                                            <div className="relative group overflow-hidden">
                                                <img
                                                    className="w-full item object-cover mb-2 h-auto rounded-xl"
                                                    src={img}
                                                    alt="artist images"
                                                    slot={index}
                                                />
                                                <Link
                                                    to={`#`}
                                                    className="absolute group-hover:opacity-100 opacity-0 duration-300 top-5 right-5 bg-primary rounded-full size-16 flex justify-center items-center cursor-pointer"
                                                    onClick={() => handleDownload(img, name)}
                                                >
                                                    <FaArrowDown size={24} />
                                                </Link>
                                                <div className="flex gap-3 items-center absolute bottom-8 left-2 z-[60] font-BoldSF">
                                                    {name}
                                                </div>
                                            </div>
                                        ))}
                                    </masonry-layout>
                                )}

                                {tab == "gifs" && (
                                    <masonry-layout gap="10" debounce="500" >
                                        {gifs?.map(({ img, name, rating }, index) => (
                                            <div className="relative group overflow-hidden">
                                                <img
                                                    className="w-full item object-cover mb-2 h-auto rounded-xl"
                                                    src={img}
                                                    alt="gif images"
                                                    slot={index}
                                                />
                                                <Link
                                                    to={`#`}
                                                    className="absolute group-hover:opacity-100 opacity-0 duration-300 top-5 right-5 bg-primary rounded-full size-16 flex justify-center items-center cursor-pointer"
                                                    onClick={() => handleDownload(img, name)}
                                                >
                                                    <FaArrowDown size={24} />
                                                </Link>
                                            </div>
                                        ))}
                                    </masonry-layout>
                                )}

                                {tab == "memes" && (
                                    <masonry-layout gap="10" debounce="500">
                                        {memes?.map(({ img, name, rating }, index) => (
                                            <div className="relative group overflow-hidden">
                                                <img
                                                    className="w-full item object-cover mb-2 h-auto rounded-xl"
                                                    src={img}
                                                    alt="memes"
                                                    slot={index}
                                                />
                                                <Link
                                                    to={`#`}
                                                    className="absolute group-hover:opacity-100 opacity-0 duration-300 top-5 right-5 bg-primary rounded-full size-16 flex justify-center items-center cursor-pointer"
                                                    onClick={() => handleDownload(img, name)}
                                                >
                                                    <FaArrowDown size={24} />
                                                </Link>
                                            </div>
                                        ))}
                                    </masonry-layout>
                                )}

                                {tab == "videos" && (
                                    <masonry-layout gap="10" debounce="500">
                                        {videos?.map(({ img, name, rating }, index) => (
                                            <div className="relative group overflow-hidden">
                                                <video
                                                    loop
                                                    playsInline
                                                    controls={true}
                                                    slot={index}
                                                    className="w-full item object-cover mb-2 h-auto rounded-xl"
                                                    style={{ objectPosition: "center" }}
                                                >
                                                    <source
                                                        src={img}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                                <Link
                                                    to={`#`}
                                                    className="absolute group-hover:opacity-100 opacity-0 duration-300 top-5 right-5 bg-primary rounded-full size-16 flex justify-center items-center cursor-pointer"
                                                    onClick={() => handleDownload(img, name)}
                                                >
                                                    <FaArrowDown size={24} />
                                                </Link>
                                            </div>
                                        ))}
                                    </masonry-layout>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;

import React from 'react'

const HeroSkeleton = () => {
    return (
        <div><div class="animate-pulse flex flex-col  min-h-[calc(100vh-150px)] herobg  max-w-[1500px] mx-auto xxl:px-4 items-center relative overflow-hidden lg:pt-20">

            <div className='flex justify-between items-center text-black w-full md:gap-1 xl:hidden'>
                <div className='flex md:gap-2 gap-10 '>
                    <div >
                        <div className="h-8 bg-slate-700 rounded w-44"></div>
                        <div className="h-4 mt-1 bg-slate-700 rounded w-32"></div>
                    </div>


                </div>
                <div className='flex items-center  gap-3 flex-wrap my-10'>
                    <div className="rounded-full bg-slate-700 h-10 w-10 md:hidden"></div>
                    <div className="h-4 bg-slate-700 rounded w-32"></div>
                    <div className="h-4 bg-slate-700 rounded w-32"></div>
                    <div className="h-4 bg-slate-700 rounded w-32"></div>
                    <div className="h-4 bg-slate-700 rounded w-32"></div>
                </div>
            </div>

            <div className="flex  xl:flex-wrap gap-3  justify-between max-w-[1500px] w-full mx-auto">
                <div className='w-full h-[550px]  bg-slate-700 rounded-lg'></div>


                <div className='w-full h-[550px] flex flex-col justify-center items-center rounded-lg'>
                    <div className="h-20 bg-slate-700 rounded w-96"></div>
                    <div className="h-10 bg-slate-700 rounded mt-2 w-72"></div>
                    <div className="rounded-full bg-slate-700 mt-4 h-10 w-44"></div>

                    <div className='flex flex-wrap mt-4 gap-2'>
                        <div className="rounded-full bg-slate-700 h-10 w-10 "></div>
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                    </div>
                    <div className="h-10 bg-slate-700 rounded mt-2 w-72"></div>
                </div>
            </div>
        </div></div>
    )
}

export default HeroSkeleton
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { Link } from 'react-router-dom';
import "react-modern-drawer/dist/index.css";
import { useTokenData } from '../hooks/useTokenData';

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    const { priceUsd, isLoading } = useTokenData();

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 50) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const formatPrice = (price) => {
        return price.toFixed(6);
    };

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleAnchorClick = (e, targetId) => {
        e.preventDefault();
        const element = document.getElementById(targetId);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            if (isOpen) setIsOpen(false);
        }
    };

    return (
        <div className="text-white max-w-[1500px] mx-auto py-3 xl:px-4 relative z-[50]">
            <div className="container lg:max-w-[100%] relative mx-auto py-2 flex items-center justify-between xxl:px-4 md:!px-0">
                <div className="flex items-start gap-4">
                    <div>
                        <Link to="/">
                            <span className="font-MonumentExtendedUltrabold tracking-widest text-3xl block">MOG COIN</span>
                            {!isLoading && (
                                <span className="font-MonumentExtended text-primary text-sm block mt-1">
                                    ${formatPrice(priceUsd)}
                                </span>
                            )}
                        </Link>
                    </div>
                </div>
                <div className="md:hidden flex items-center space-x-8 font-MonumentExtended text-lg tracking-wide">
                    <Link to="/" className="hover:text-primary text-xl">
                        <span className="text-2xl">😹</span>
                    </Link>

                    <a href="#about" onClick={(e) => handleAnchorClick(e, 'about')} className="hover:text-primary text-xl">About</a>


                    <a href="#buy" onClick={(e) => handleAnchorClick(e, 'buy')} className="hover:text-primary text-xl">How To Buy</a>

                    <div className="group relative flex justify-center items-center cursor-pointer">
                        <Link to="#" className="hover:text-primary text-xl">Content</Link>
                        <div className="absolute hidden group-hover:flex text-nowrap bg-primary -bottom-12 font-Cutiemollydemo text-white rounded-[20px] px-5 py-2">
                            Coming soon
                            <div className="absolute left-1/2 transform -translate-x-1/2 top-[-7px] w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-primary"></div>
                        </div>
                    </div>
                    <a href="#faq" onClick={(e) => handleAnchorClick(e, 'faq')} className="hover:text-primary text-xl">FAQ</a>
                </div>

                <div className="px-4 md:block hidden">
                    <button onClick={toggleDrawer} aria-label="drawer button">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M3.75 17.25h16.5" />
                        </svg>
                    </button>
                </div>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="relative"
            >
                <div className="absolute text-black right-4 top-5">
                    <MdClose fontSize={28} onClick={() => setIsOpen(false)} />
                </div>
                <div className="px-4 py-10 text-lg space-y-10 mt-5 font-MonumentExtended">
                    <div className="flex flex-col space-y-5 text-black">
                        <Link to="/" className="hover:text-primary cursor-pointer flex items-center gap-2">
                            <span className="text-2xl">😹</span>
                            <span>Home</span>
                        </Link>
                        <a href="#about" onClick={(e) => handleAnchorClick(e, 'about')} className="hover:text-primary cursor-pointer">About</a>

                        <div className="flex items-center gap-3">
                            <Link to="#" className="hover:text-primary cursor-pointer">Content</Link>
                            <div className="text-[10px] bg-primary text-white rounded-full w-full text-center">Coming soon</div>
                        </div>

                        <a href="#buy" onClick={(e) => handleAnchorClick(e, 'buy')} className="hover:text-primary cursor-pointer">How To Buy</a>
                        <a href="#bridge" onClick={(e) => handleAnchorClick(e, 'bridge')} className="hover:text-primary cursor-pointer">How to Bridge</a>
                        <a href="#faq" onClick={(e) => handleAnchorClick(e, 'faq')} className="hover:text-primary cursor-pointer">FAQ</a>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

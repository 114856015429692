import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'

import { useTokenData } from '../../hooks/useTokenData';

const Info = () => {
    const { marketCap, liquidity, volume24h, isLoading } = useTokenData();

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US').format(Math.round(num));
    };
    return (
        <div className="bg-yellow-50 py-3">
            <div className="flex  items-center justify-between max-w-[1500px] mx-auto xxl:px-4">
                <div className="flex  gap-20 md:gap-5">
                    <div>
                        <div className="text-[#7B01FD] text-sm md:text-[10px]  tracking-widest md:tracking-normal">
                            MARKET CAP
                        </div>
                        <div className="text-[#7B01FD] font-MonumentExtended md:text-xs tracking-widest md:tracking-normal">
                            ${isLoading ? "Loading..." : formatNumber(marketCap)}
                        </div>
                    </div>

                    <div>
                        <div className="text-[#7B01FD] text-sm md:text-[10px]  tracking-widest md:tracking-normal text-nowrap">
                            VOLUME (24h)
                        </div>
                        <div className="text-[#7B01FD] font-MonumentExtended md:text-xs tracking-widest md:tracking-normal">
                            ${isLoading ? "Loading..." : formatNumber(volume24h)}
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <a 
                        href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="bg-[#7a27d3] md:mx-auto text-white md:text-xs font-MonumentExtended  pt-2 pb-1.5 px-4 md:px-1.5 rounded-full hover:bg-primary tracking-widest md:tracking-normal">
                            BUY NOW
                        </button>
                    </a>
                    <div className="flex items-center gap-3 justify-center md:hidden">
                        <a
                            href="https://t.me/mogcoinpls"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center rounded-full size-[44px] hover:bg-primary cursor-pointer z-50 bg-[#7B01FD] border border-primary text-primary hover:text-black relative "
                        >
                            {" "}
                            <FaTelegramPlane className="size-[24px]" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info
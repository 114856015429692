const DEXSCREENER_BASE_URL = 'https://api.dexscreener.com/latest';
const MOG_TOKEN_ADDRESS = '0x495d9b70480A22a82D0FB81981480764BA55550e';

async function fetchTokenData() {
    try {
        const response = await fetch(`${DEXSCREENER_BASE_URL}/dex/tokens/${MOG_TOKEN_ADDRESS}`);
        const data = await response.json();

        if (!data.pairs || data.pairs.length === 0) {
            throw new Error('No pairs found');
        }

        // Get the main pairs (PulseX and 9mm with significant liquidity)
        const significantPairs = data.pairs.filter(pair => 
            pair.chainId === 'pulsechain' && 
            (pair.dexId === 'pulsex' || pair.dexId === '9mm') &&
            parseFloat(pair.liquidity?.usd || 0) > 10000 && // Only pairs with significant liquidity
            parseFloat(pair.marketCap || 0) > 1000000 // Market cap > 1M to filter out incorrect values
        );

        // Sort by liquidity to get the most liquid pairs
        significantPairs.sort((a, b) => 
            (parseFloat(b.liquidity?.usd) || 0) - (parseFloat(a.liquidity?.usd) || 0)
        );

        // Get market cap from the most liquid pair
        const mainPair = significantPairs[0];
        const marketCap = mainPair ? parseFloat(mainPair.marketCap) : 0;
        const priceUsd = mainPair ? parseFloat(mainPair.priceUsd) : 0;

        // Calculate totals from significant pairs
        let totalLiquidity = 0;
        let total24hVolume = 0;

        significantPairs.forEach(pair => {
            totalLiquidity += parseFloat(pair.liquidity?.usd || 0);
            total24hVolume += parseFloat(pair.volume?.h24 || 0);
        });

        return {
            marketCap,
            liquidity: totalLiquidity,
            volume24h: total24hVolume,
            priceUsd,
            lastUpdated: new Date().toISOString(),
            pairCount: significantPairs.length,
            pairs: significantPairs
        };
    } catch (error) {
        console.error('Error fetching MOG token data:', error);
        throw error;
    }
}

export { fetchTokenData };
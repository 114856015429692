import { useState, useEffect } from 'react';
import { fetchTokenData } from '../services/tokenPriceService';

export function useTokenData() {
    const [tokenData, setTokenData] = useState({
        marketCap: 0,
        liquidity: 0,
        volume24h: 0,
        priceUsd: 0,
        lastUpdated: null,
        isLoading: true,
        error: null
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchTokenData();
                setTokenData({
                    ...data,
                    isLoading: false,
                    error: null
                });
            } catch (error) {
                setTokenData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: error.message
                }));
            }
        };

        fetchData();
        // Optionally add an interval to refresh data
        const interval = setInterval(fetchData, 30000); // refresh every 30 seconds

        return () => clearInterval(interval);
    }, []);

    return tokenData;
}
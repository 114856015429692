export const plateform = [
    {
        img: '/assets/images/dexscreener_big.png',
        title: 'DEXSCREENER',
        href: 'https://dexscreener.com/pulsechain/0x8a41dcd8cfd5f34f9ba9731fbdbf34b45b1b41aa'
    },
    {
        img: '/assets/images/dextools.png',
        title: 'DEXTOOLS',
        href: 'https://www.dextools.io/app/en/pulse/pair-explorer/0xef01aacd087de6bb963d7da1d2ef6ad0d203701a'
    },
    {
        img: '/assets/images/moralis.png',
        title: 'MORALIS',
        href: 'https://moralis.com/chain/pulse/token/price/mog-coin-4'
    },
    {
        img: '/assets/images/gopulse.webp',
        title: 'GO PULSE',
        href: 'https://gopulse.com/token/0x495d9b70480a22a82d0fb81981480764ba55550e'
    },
    {
        img: '/assets/images/pulsex.png',
        title: 'PULSEX',
        href: 'https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e'
    },
    {
        img: '/assets/images/nineinch.png',
        title: '9INCH',
        href: 'https://app.9inch.io/swap?chain=pulse&outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e'
    },
    {
        img: '/assets/images/9mm.png',
        title: '9MM',
        href: 'https://v3.9mm.pro/swap?chain=pulsechain&outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e'
    },
    {
        img: '/assets/images/piteas.png',
        title: 'PITEAS',
        href: 'https://widget.piteas.io/#/swap?inputCurrency=PLS&outputCurrency=0x495d9b70480A22a82D0FB81981480764BA55550e&exactField=input&exactAmount=1000000&theme=dark'
    },
]

export const buy = [
    {
        title: 'Get a Wallet',
        des: 'First you’ll need to download a cryptocurrency wallet like Metamask ( Hotline ) or Rabby Hotline ) which are available as free apps for mobile on the apple( hotlink ) and google ( hotline ) app stores, both are also available to download as desktop browser extensions.'
    },
    {
        title: 'Purchase $PLS or $ETH ',
        des: `You can buy Pulsechain ($PLS) or Etheruem (ETH) directly inside MetaMask or Rabbi or by using a centralised exchange (after purchase you must transfer to your MetaMask wallet), once you have Pulsechain ($PLS) or Ethereum ($ETH ) in your wallet you will be able to purchase $MOG via PulseX (for $PLS) or Uniswap (for $ETH) using the official contract adresseses below. Official Contract Address on Pulsechain 0x0000,
                    Official Contract Address on Ethereum
                    0x0000`
    },

]
export const images = [
    {
        img: "https://images.pexels.com/photos/1118873/pexels-photo-1118873.jpeg?cs=srgb&dl=pexels-jplenio-1118873.jpg&fm=jpg",
        height: "488",
        name: "Aditya Gadhvi",
        rating: 5,
        id: 1,
    },

    {
        img: "https://images.pexels.com/photos/325117/pexels-photo-325117.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "328",
        name: "Kinjal Dave",
        rating: 4,
        id: 2,
    },
    {
        img: "https://images.pexels.com/photos/2310641/pexels-photo-2310641.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "128",
        name: "Geeta Rabali",
        rating: 4,
        id: 3,
    },
    {
        img: "https://images.pexels.com/photos/2028885/pexels-photo-2028885.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "339",
        name: "Diljit Dostana",
        rating: 4,
        id: 4,
    },
    {
        img: "https://images.pexels.com/photos/2422497/pexels-photo-2422497.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "388",
        name: "Taylor Swift",
        rating: 4.5,
        id: 5,
    },
    {
        img: "https://images.pexels.com/photos/3408552/pexels-photo-3408552.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "528",
        name: "Billie Eilish",
        rating: 4,
        id: 6,
    },
    {
        img: "https://images.pexels.com/photos/3178798/pexels-photo-3178798.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Arijit Singh",
        rating: 4,
        id: 7,
    },
    {
        img: "https://images.pexels.com/photos/2144326/pexels-photo-2144326.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "239",
        name: "Lady Gaga",
        rating: 4,
        id: 8,
    },

    {
        img: "https://images.pexels.com/photos/3540375/pexels-photo-3540375.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "339",
        name: "Aishwarya Majmudar",
        rating: 5,
        id: 9,
    },
    {
        img: "https://images.pexels.com/photos/1784577/pexels-photo-1784577.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "288",
        name: "Kirtidan Gadhvi",
        rating: 4,
        id: 10,
    },
    {
        img: "https://images.pexels.com/photos/756856/pexels-photo-756856.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Shreya Ghosal",
        rating: 4,
        id: 11,
    },
    {
        img: "https://images.pexels.com/photos/2310648/pexels-photo-2310648.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Justine Biber",
        rating: 4,
        id: 12,
    },
    {
        img: "https://images.pexels.com/photos/29537800/pexels-photo-29537800/free-photo-of-aerial-view-of-scenic-mountain-valley-and-river.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Hemant Chuhan",
        rating: 4,
        id: 13,
    },
    {
        img: "https://images.pexels.com/photos/29541099/pexels-photo-29541099/free-photo-of-solitary-seagull-resting-on-a-reflective-pond.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Hitesh Antala",
        rating: 4,
        id: 14,
    },
    {
        img: "https://images.pexels.com/photos/29532457/pexels-photo-29532457/free-photo-of-solitary-figure-with-umbrella-on-empty-road.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Jignesh Kaviraj",
        rating: 4,
        id: 15,
    },
    {
        img: "https://images.pexels.com/photos/29527537/pexels-photo-29527537/free-photo-of-rainy-day-in-boston-with-custom-house-view.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Dua Lipa",
        rating: 4,
        id: 16,
    },
    {
        img: "https://images.pexels.com/photos/29528834/pexels-photo-29528834/free-photo-of-scenic-beach-sunset-with-reflective-tide.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Selena Gomez",
        rating: 4,
        id: 17,
    },
    {
        img: "https://images.pexels.com/photos/29526802/pexels-photo-29526802/free-photo-of-dramatic-winter-sunset-over-snowy-merzifon-landscape.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Rihana",
        rating: 4,
        id: 18,
    },
    {
        img: "https://images.pexels.com/photos/29525067/pexels-photo-29525067/free-photo-of-vertical-contrail-against-evening-sky-in-india.jpeg?auto=compress&cs=tinysrgb&w=600",
        height: "228",
        name: "Ap Dhillon",
        rating: 4,
        id: 19,
    },
];
export const gifs = [
    {
        img: "https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnZka2cwZGdzNGE2MGJ2d29wZWk5dHVkNzA3bHF1bXZ1c25xdjhiZyZlcD12MV9naWZzX3RyZW5kaW5nJmN0PWc/jle0nmfHz4YHxsOc6d/giphy.webp",
        height: "488",
        name: "Aditya Gadhvi",
        rating: 5,
        id: 1,
    },

    {
        img: "https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnZka2cwZGdzNGE2MGJ2d29wZWk5dHVkNzA3bHF1bXZ1c25xdjhiZyZlcD12MV9naWZzX3RyZW5kaW5nJmN0PWc/Jalikml2XiUgw/giphy.webp",
        height: "328",
        name: "Kinjal Dave",
        rating: 4,
        id: 2,
    },
    {
        img: "https://media1.giphy.com/media/duNowzaVje6Di3hnOu/200.webp?cid=790b76116vdkg0dgs4a60bvwopei9tud707lqumvusnqv8bg&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "128",
        name: "Geeta Rabali",
        rating: 4,
        id: 3,
    },
    {
        img: "https://media4.giphy.com/media/DrJm6F9poo4aA/giphy.webp?cid=790b76116vdkg0dgs4a60bvwopei9tud707lqumvusnqv8bg&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "339",
        name: "Diljit Dostana",
        rating: 4,
        id: 4,
    },
    {
        img: "https://media3.giphy.com/media/vVzH2XY3Y0Ar6/giphy.webp?cid=82a1493bniwa0drtki08cjbnflgbvpjyr0vwegn74yu6k3kz&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "388",
        name: "Taylor Swift",
        rating: 4.5,
        id: 5,
    },
    {
        img: "https://media4.giphy.com/media/3ndAvMC5LFPNMCzq7m/giphy.webp?cid=82a1493b7u8jh6i7md2ffveeh1oi0n5g73flalv3d56uzyb4&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "528",
        name: "Billie Eilish",
        rating: 4,
        id: 6,
    },
    {
        img: "https://media4.giphy.com/media/26gsspfbt1HfVQ9va/200.webp?cid=82a1493b7u8jh6i7md2ffveeh1oi0n5g73flalv3d56uzyb4&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "228",
        name: "Arijit Singh",
        rating: 4,
        id: 7,
    },
    {
        img: "https://media1.giphy.com/media/UO5elnTqo4vSg/200.webp?cid=82a1493bifgs5h8e5fvfcx3243zr6wfml8tmq8rtmbnz4ys3&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "239",
        name: "Lady Gaga",
        rating: 4,
        id: 8,
    },

    {
        img: "https://media4.giphy.com/media/bbshzgyFQDqPHXBo4c/giphy.webp?cid=82a1493buhzq6q80683qwsl85y9zfh2xc5jdatuu635dwdmm&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "339",
        name: "Aishwarya Majmudar",
        rating: 5,
        id: 9,
    },
    {
        img: "https://media2.giphy.com/media/xTiN0CNHgoRf1Ha7CM/200.webp?cid=82a1493b9vnsqsyr0oebem9y1ns8f8f2900c0v801kqyq85z&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "288",
        name: "Kirtidan Gadhvi",
        rating: 4,
        id: 10,
    },
    {
        img: "https://media1.giphy.com/media/xnFN6mWrj7JGTqRj70/giphy.webp?cid=82a1493br3l19z4oo6o97bx4hdjz4b5edulkd8w6dwrvsfvx&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "228",
        name: "Shreya Ghosal",
        rating: 4,
        id: 11,
    },
    {
        img: "https://media0.giphy.com/media/5GoVLqeAOo6PK/200.webp?cid=82a1493bq5h97gfh1iry7tbaoqujljpak6qf2quhwgef4vib&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "228",
        name: "Justine Biber",
        rating: 4,
        id: 12,
    },
    {
        img: "https://media1.giphy.com/media/TiOtVRx07iGqLmWk6m/giphy.webp?cid=82a1493bzsu2am57c7t5u0le55xhw4whp0picv9wb54pqggg&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "228",
        name: "Hemant Chuhan",
        rating: 4,
        id: 13,
    },
    {
        img: "https://media0.giphy.com/media/F6PFPjc3K0CPe/200.webp?cid=82a1493b9ewk9lx7f0f5h8bczpms57v9dc1dz7v4ko9vaish&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "228",
        name: "Hitesh Antala",
        rating: 4,
        id: 14,
    },
    {
        img: "https://media0.giphy.com/media/ib6AkraOdGpS8GPnXl/giphy.webp?cid=82a1493b9ewk9lx7f0f5h8bczpms57v9dc1dz7v4ko9vaish&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "228",
        name: "Jignesh Kaviraj",
        rating: 4,
        id: 15,
    },
    {
        img: "https://media4.giphy.com/media/lMsT2f47tDxFMYdJMC/giphy.webp?cid=82a1493b146atgdvuhaf5m3i31ynz8b2wqnstim77kitbgi6&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "228",
        name: "Dua Lipa",
        rating: 4,
        id: 16,
    },
    {
        img: "https://media1.giphy.com/media/7zMvSDWl3RkDSpOjBC/200.webp?cid=82a1493b146atgdvuhaf5m3i31ynz8b2wqnstim77kitbgi6&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "228",
        name: "Selena Gomez",
        rating: 4,
        id: 17,
    },
    {
        img: "https://media4.giphy.com/media/dzH4bg73Io8mI/giphy.webp?cid=82a1493b146atgdvuhaf5m3i31ynz8b2wqnstim77kitbgi6&ep=v1_gifs_trending&rid=giphy.webp&ct=g",
        height: "228",
        name: "Rihana",
        rating: 4,
        id: 18,
    },
    {
        img: "https://media1.giphy.com/media/kBbLghDMVMZr4mygzc/200.webp?cid=82a1493bmjparm9ihxjbfkke22jbyqwe8kmx3rb2i18gjt5g&ep=v1_gifs_trending&rid=200.webp&ct=g",
        height: "228",
        name: "Ap Dhillon",
        rating: 4,
        id: 19,
    },
];
export const memes = [
    {
        img: "https://img.freepik.com/free-vector/simple-vibing-cat-square-meme_742173-4493.jpg",
        height: "488",
        name: "Aditya Gadhvi",
        rating: 5,
        id: 1,
    },

    {
        img: "https://images.theconversation.com/files/38926/original/5cwx89t4-1389586191.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=926&fit=clip",
        height: "328",
        name: "Kinjal Dave",
        rating: 4,
        id: 2,
    },
    {
        img: "https://media.sproutsocial.com/uploads/meme-example.jpg",
        height: "128",
        name: "Geeta Rabali",
        rating: 4,
        id: 3,
    },
    {
        img: "https://media.istockphoto.com/id/538665020/photo/internet-meme-why-you-no-rage-face-3d-illustration.jpg?s=612x612&w=0&k=20&c=5D_g8Jy8kqg5Op2bb4RvcH8_6y0HGPqt29TKDrEqLyM=",
        height: "339",
        name: "Diljit Dostana",
        rating: 4,
        id: 4,
    },
    {
        img: "https://ichef.bbci.co.uk/images/ic/1920xn/p072ms6r.jpg",
        height: "388",
        name: "Taylor Swift",
        rating: 4.5,
        id: 5,
    },
    {
        img: "https://miro.medium.com/v2/resize:fit:1400/1*GI-td9gs8D5OKZd19mAOqA.png",
        height: "528",
        name: "Billie Eilish",
        rating: 4,
        id: 6,
    },
    {
        img: "https://img.etimg.com/thumb/width-1200,height-1200,imgsize-37088,resizemode-75,msid-98904538/magazines/panache/hilarious-earthquake-memes-trend-on-twitter-after-strong-tremors-jolt-north-india.jpg",
        height: "228",
        name: "Arijit Singh",
        rating: 4,
        id: 7,
    },
    {
        img: "https://assets.entrepreneur.com/content/3x2/2000/20180703190744-rollsafe-meme.jpeg",
        height: "239",
        name: "Lady Gaga",
        rating: 4,
        id: 8,
    },

    {
        img: "https://images.indianexpress.com/2017/04/work-meme-12.jpg",
        height: "339",
        name: "Aishwarya Majmudar",
        rating: 5,
        id: 9,
    },
    {
        img: "https://lh7-us.googleusercontent.com/KmQDqJKyqlS5rqDWDeL6piC0zUz-NatpyoLj0UzYF6Osa6pLjqkfxUAZ6OCEP17sZ77dCq13XPrmpmSHfmnNDonnrclsnviMNFQNdaCWn_TMk2BBrFbjQr2k-qXyQveW2JUs2emzbhuRwMUJEss5u6B5MWp2FAIqLQ9tnxc9rCGhwhNoK51C61f6snP6LHn6iqEtYGkZnw",
        height: "288",
        name: "Kirtidan Gadhvi",
        rating: 4,
        id: 10,
    },
    {
        img: "https://static.wixstatic.com/media/f6bccd_27c5342e39874f3ba9727eb84ba91e3a~mv2.png/v1/fill/w_568,h_378,al_c,lg_1,q_85,enc_auto/f6bccd_27c5342e39874f3ba9727eb84ba91e3a~mv2.png",
        height: "228",
        name: "Shreya Ghosal",
        rating: 4,
        id: 11,
    },
    {
        img: "https://www.care.com/c/wp-content/uploads/sites/2/2021/03/memes-for-kids-1.png",
        height: "228",
        name: "Justine Biber",
        rating: 4,
        id: 12,
    },
    {
        img: "https://i.pinimg.com/736x/cc/11/9b/cc119b1a0d773008bd2a64bf5421bbfe.jpg",
        height: "228",
        name: "Hemant Chuhan",
        rating: 4,
        id: 13,
    },
    {
        img: "https://community.atlassian.com/t5/image/serverpage/image-id/263783i28EFE632226231DD/image-size/large?v=v2&px=999",
        height: "228",
        name: "Hitesh Antala",
        rating: 4,
        id: 14,
    },
    {
        img: "https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1nxwMl.img?w=500&h=500&m=6&x=100&y=65&s=228&d=228",
        height: "228",
        name: "Jignesh Kaviraj",
        rating: 4,
        id: 15,
    },
    {
        img: "https://thunderdungeon.com/wp-content/uploads/2024/10/tesla-ai-robot-memes-1-20241018.jpg",
        height: "228",
        name: "Dua Lipa",
        rating: 4,
        id: 16,
    },
    {
        img: "https://www.socialpilot.co/wp-content/uploads/2023/02/when-every-friend.webp",
        height: "228",
        name: "Selena Gomez",
        rating: 4,
        id: 17,
    },
    {
        img: "https://images.prismic.io/tryotter/1c46227d-ef9c-40a5-acec-55bbf3a6347e_body_UItablet-19-min.png?auto=compress%2Cformat&fit=max&w=1600&h=900",
        height: "228",
        name: "Rihana",
        rating: 4,
        id: 18,
    },
    {
        img: "https://www.revechat.com/wp-content/uploads/2022/11/funny-customer-meme-06.jpg",
        height: "228",
        name: "Ap Dhillon",
        rating: 4,
        id: 19,
    },
];
export const videos = [
    {
        img: "https://videos.pexels.com/video-files/1448735/1448735-uhd_2732_1440_24fps.mp4",
        height: "488",
        name: "Aditya Gadhvi",
        rating: 5,
        id: 1,
    },

    {
        img: "https://videos.pexels.com/video-files/6813969/6813969-sd_360_624_30fps.mp4",
        height: "328",
        name: "Kinjal Dave",
        rating: 4,
        id: 2,
    },
    {
        img: "https://videos.pexels.com/video-files/6257087/6257087-hd_1280_720_25fps.mp4",
        height: "128",
        name: "Geeta Rabali",
        rating: 4,
        id: 3,
    },
    {
        img: "https://videos.pexels.com/video-files/8553232/8553232-hd_1920_1080_30fps.mp4",
        height: "339",
        name: "Diljit Dostana",
        rating: 4,
        id: 4,
    },
    {
        img: "https://videos.pexels.com/video-files/5966051/5966051-uhd_2560_1440_30fps.mp4",
        height: "388",
        name: "Taylor Swift",
        rating: 4.5,
        id: 5,
    },
    {
        img: "https://videos.pexels.com/video-files/18518728/18518728-hd_1080_1920_30fps.mp4",
        height: "528",
        name: "Billie Eilish",
        rating: 4,
        id: 6,
    },
    {
        img: "https://videos.pexels.com/video-files/1448735/1448735-uhd_2732_1440_24fps.mp4",
        height: "488",
        name: "Aditya Gadhvi",
        rating: 5,
        id: 1,
    },

    {
        img: "https://videos.pexels.com/video-files/4336837/4336837-sd_640_360_30fps.mp4",
        height: "328",
        name: "Kinjal Dave",
        rating: 4,
        id: 2,
    },
    {
        img: "https://videos.pexels.com/video-files/28379895/12371330_360_640_60fps.mp4",
        height: "128",
        name: "Geeta Rabali",
        rating: 4,
        id: 3,
    },
    {
        img: "https://videos.pexels.com/video-files/8552814/8552814-sd_360_640_30fps.mp4",
        height: "339",
        name: "Diljit Dostana",
        rating: 4,
        id: 4,
    },
    {
        img: "https://videos.pexels.com/video-files/3744556/3744556-sd_640_360_30fps.mp4",
        height: "388",
        name: "Taylor Swift",
        rating: 4.5,
        id: 5,
    },
    {
        img: "https://videos.pexels.com/video-files/27441905/12145625_360_640_30fps.mp4",
        height: "528",
        name: "Billie Eilish",
        rating: 4,
        id: 6,
    },

];
import React from 'react'

const BridgeSkeleton = () => {
    return (
        <div className="animate-pulse flex flex-col items-center justify-between max-w-[1500px] mx-auto xxl:px-4 my-40 md:my-20">
            <div className='w-1/3 h-12 bg-slate-700 mx-auto mt-40 rounded'></div>
            <div className='w-1/3 h-12 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='w-full h-20 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='w-1/2 h-80 bg-slate-700 mx-auto mt-5 rounded'></div>
            {/* more way */}
            <div className='w-1/3 h-12 bg-slate-700 mx-auto mt-40 rounded'></div>
            <div className='w-1/2 h-12 bg-slate-700 mx-auto mt-5 rounded'></div>
            <div className='grid grid-cols-4 gap-10 mt-5 gap-x-8 gap-y-16 py-10 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2'>
                {[...Array(8)].map((item) => (
                    <div className='size-32 bg-slate-700 rounded-full'></div>
                ))}
            </div>
        </div>
    )
}

export default BridgeSkeleton
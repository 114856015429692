import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MogCoinNew from './pages/MogCoinNew';
import Content from './pages/Content';
import LoadingScreen from './components/LoadingScreen';
import ContentDemo from './pages/Contentdemo';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Match this timeout duration with the one in LoadingScreen.jsx (3000ms)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<MogCoinNew />} />
            <Route path="/content" element={<Content />} />
              <Route path="/content2" element={<ContentDemo />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;

import React from 'react'

const HowtobuySkeleton = () => {
    return (
        <div className="animate-pulse flex flex-col items-center justify-between max-w-[1500px] mx-auto xxl:px-4 ">
            <div className='w-72 h-12 bg-slate-700 mx-auto mt-40 rounded'></div>


            <div className='grid grid-cols-2 gap-5 w-full mt-10'>
                <div className='h-56 w-full bg-slate-700 rounded'></div>
                <div className='h-56 w-full bg-slate-700 rounded'></div>
                <div className='h-56 w-full bg-slate-700 rounded'></div>
                <div className='h-56 w-full bg-slate-700 rounded'></div>
            </div>
        </div>
    )
}

export default HowtobuySkeleton
import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ initialText = "LOADING HIGHER TECHNOLOGY" }) => {
  const [text, setText] = useState(initialText);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Make sure this duration matches the timeout in App.js
    const duration = 2500;
    const interval = 10;
    const steps = duration / interval;
    const increment = 100 / steps;

    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(timer);
          return 100;
        }
        return Math.min(prev + increment, 100);
      });
    }, interval);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed inset-0">
      {/* Video Background */}
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source
          src="/assets/videos/MogLoad_003_full.mp4"
          type="video/mp4"
        // src="/assets/videos/MogLoad_003_full-ezgif.com-gif-maker.webm"
        // type="video/webm"
        />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col justify-end pb-24 md:pb-24">
        <div className="text-center">
          {/* Animated Words */}
          <div className="w-[90vw] mx-auto flex flex-row xxxs:flex-col xs:flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-row justify-center items-center gap-1 xxxs:gap-2 xs:gap-2 sm:gap-2 md:gap-2 lg:gap-2 xl:gap-2 2xl:gap-16 mb-6">
            <span
              className="font-MonumentExtendedUltrabold text-primary animate-pulse-opacity whitespace-nowrap text-center"
              style={{ 
                animationDelay: '0s',
                fontSize: 'clamp(24px, 5vw, 48px)'
              }}
            >
              LOADING
            </span>
            <div className="flex flex-row justify-center items-center gap-1 xxxs:gap-2 xs:gap-2 sm:gap-2 md:gap-2 lg:gap-2 xl:gap-2 2xl:gap-16">
              <span
                className="font-MonumentExtendedUltrabold text-primary animate-pulse-opacity whitespace-nowrap"
                style={{ 
                  animationDelay: '0.2s',
                  fontSize: 'clamp(24px, 5vw, 48px)'
                }}
              >
                HIGHER
              </span>
              <span
                className="font-MonumentExtendedUltrabold text-primary animate-pulse-opacity whitespace-nowrap"
                style={{ 
                  animationDelay: '0.4s',
                  fontSize: 'clamp(24px, 5vw, 48px)'
                }}
              >
                TECHNOLOGY
              </span>
            </div>
          </div>

          {/* Progress Bar */}
          <div className="w-[45vw] md:w-64 mx-auto">
            <div className="h-2 bg-transparent rounded-full border border-primary overflow-hidden">
              <div 
                className="h-full bg-primary rounded-full transition-all duration-100 ease-out"
                style={{ 
                  width: `${progress}%`,
                  transition: 'width 100ms ease-out'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
import React from 'react'

const CarSkeleton = () => {
    return (
        <div>
            <div className="rounded bg-slate-700 h-10 w-full"></div>
        </div>
    )
}

export default CarSkeleton